import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  users: [
    {
      id: 1,
      fullName: "Galen Slixby",
      company: "Yotz PVT LTD",
      role: "author",
      username: "gslixby0",
      country: "El Salvador",
      contact: "(479) 232-9151",
      billing: "gslixby0@abc.net.au",
      currentPlan: "enterprise",
      status: "inactive",
      avatar: "",
    },
    {
      id: 2,
      fullName: "Halsey Redmore",
      company: "Skinder PVT LTD",
      role: "author",
      username: "hredmore1",
      country: "Albania",
      contact: "(472) 607-9137",
      billing: "hredmore1@imgur.com",
      currentPlan: "team",
      status: "pending",
      avatar: require("@/assets/images/avatars/10.png"),
    },
    {
      id: 3,
      fullName: "Marjory Sicely",
      company: "Oozz PVT LTD",
      role: "maintainer",
      username: "msicely2",
      country: "Russia",
      contact: "(321) 264-4599",
      billing: "msicely2@who.int",
      currentPlan: "enterprise",
      status: "active",
      avatar: require("@/assets/images/avatars/1.png"),
    },
    {
      id: 4,
      fullName: "Cyrill Risby",
      company: "Oozz PVT LTD",
      role: "maintainer",
      username: "crisby3",
      country: "China",
      contact: "(923) 690-6806",
      billing: "crisby3@wordpress.com",
      currentPlan: "team",
      status: "inactive",
      avatar: require("@/assets/images/avatars/9.png"),
    },
    {
      id: 5,
      fullName: "Maggy Hurran",
      company: "Aimbo PVT LTD",
      role: "subscriber",
      username: "mhurran4",
      country: "Pakistan",
      contact: "(669) 914-1078",
      billing: "mhurran4@yahoo.co.jp",
      currentPlan: "enterprise",
      status: "pending",
      avatar: require("@/assets/images/avatars/10.png"),
    },
    {
      id: 6,
      fullName: "Silvain Halstead",
      company: "Jaxbean PVT LTD",
      role: "author",
      username: "shalstead5",
      country: "China",
      contact: "(958) 973-3093",
      billing: "shalstead5@shinystat.com",
      currentPlan: "company",
      status: "active",
      avatar: "",
    },
    {
      id: 7,
      fullName: "Breena Gallemore",
      company: "Jazzy PVT LTD",
      role: "subscriber",
      username: "bgallemore6",
      country: "Canada",
      contact: "(825) 977-8152",
      billing: "bgallemore6@boston.com",
      currentPlan: "company",
      status: "pending",
      avatar: "",
    },
    {
      id: 8,
      fullName: "Kathryne Liger",
      company: "Pixoboo PVT LTD",
      role: "author",
      username: "kliger7",
      country: "France",
      contact: "(187) 440-0934",
      billing: "kliger7@vinaora.com",
      currentPlan: "enterprise",
      status: "pending",
      avatar: require("@/assets/images/avatars/9.png"),
    },
    {
      id: 9,
      fullName: "Franz Scotfurth",
      company: "Tekfly PVT LTD",
      role: "subscriber",
      username: "fscotfurth8",
      country: "China",
      contact: "(978) 146-5443",
      billing: "fscotfurth8@dailymotion.com",
      currentPlan: "team",
      status: "pending",
      avatar: require("@/assets/images/avatars/2.png"),
    },
    {
      id: 10,
      fullName: "Jillene Bellany",
      company: "Gigashots PVT LTD",
      role: "maintainer",
      username: "jbellany9",
      country: "Jamaica",
      contact: "(589) 284-6732",
      billing: "jbellany9@kickstarter.com",
      currentPlan: "company",
      status: "inactive",
      avatar: require("@/assets/images/avatars/9.png"),
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet("/apps/user/roles").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.users.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (plan || user.currentPlan) &&
      user.status === (status || user.status),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      users: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost("/apps/user/roles").reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data);

  // Assign Status
  user.status = "active";

  // Assign Role (My Edit)
  user.role = "admin";

  // Assign Plane (My Edit)
  user.currentPlan = "basic";

  // Assign Billing (My Edit)
  user.billing = "Auto Debit";

  const { length } = data.users;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.users[length - 1].id;
  }
  user.id = lastIndex + 1;

  data.users.push(user);

  return [201, { user }];
});

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/roles\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.users.findIndex(e => e.id === userId);
  const user = data.users[userIndex];

  if (user) return [200, user];
  return [404];
});
