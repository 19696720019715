import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  campaigns: [
    {
      id: 1,
      campaignName: "Mother day 1",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 2,
      campaignName: "Mother day 2",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 3,
      campaignName: "Mother day 3",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 4,
      campaignName: "Mother day 4",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 5,
      campaignName: "Mother day 5",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 6,
      campaignName: "Mother day 6",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 7,
      campaignName: "Mother day 7",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 8,
      campaignName: "Mother day 8",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
    {
      id: 9,
      campaignName: "Mother day 9",
      estimatedBudget: "50,000",
      actualBudget: "40,000",
      rangeDate: "2021-12-14 to 2021-12-22",
      return: "1000",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Campaigns
// ------------------------------------------------
mock.onGet("/marketing/campaigns/campaign").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.campaigns.filter(
    campaign => campaign.campaignName.toLowerCase().includes(queryLowered),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      campaigns: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new campaign
// ------------------------------------------------
mock.onPost("/marketing/campaigns/campaign").reply(config => {
  // Get event from post data
  const { campaign } = JSON.parse(config.data);

  const { length } = data.campaigns;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.campaigns[length - 1].id;
  }
  campaign.id = lastIndex + 1;

  data.campaigns.push(campaign);

  return [201, { campaign }];
});

// ------------------------------------------------
// GET: Return Single Campaign
// ------------------------------------------------
mock.onGet(/\/marketing\/campaigns\/campaign\/\d+/).reply(config => {
  // Get event id from URL
  let campaignId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  campaignId = Number(campaignId);

  const campaignIndex = data.campaigns.findIndex(e => e.id === campaignId);
  const campaign = data.campaigns[campaignIndex];

  if (campaign) return [200, campaign];
  return [404];
});
