import mock from "./mock";

/* eslint-disable import/extensions */

// JWT
import "./jwt";

// Table
import "./data/extensions/good-table";
import "./data/extensions/auto-suggest";
// import "./data/card/card-statistics";
// import "./data/card/card-analytics";

// Apps
import "./data/apps/calendar";
import "./data/apps/user";
import "./data/apps/permessions";
import "./data/apps/roles";
import "./data/apps/invoice";
import "./data/apps/todo";
import "./data/apps/email";
import "./data/apps/chat";
// import "./data/apps/eCommerce";

// dashboard
import "./data/dashboard/analytics";
import "./data/dashboard/ecommerce";

// pages
import "./data/pages/faq-data";
import "./data/pages/knowledge-base";
import "./data/pages/pricing-data";
import "./data/pages/account-setting";
import "./data/pages/profile-data";
import "./data/pages/blog";

// marketing
import "./data/marketing/campaigns";
import "./data/marketing/channels";
import "./data/marketing/medium";

// books
import "./data/books/books-orders";
import "./data/books/books-estimates";
import "./data/books/books-credit-notes";
import "./data/books/books-expenses";
import "./data/books/books-payments";
/* eslint-enable import/extensions */

mock.onAny().passThrough(); // forwards the matched request over network
