export default {
  namespaces: true,
  state: {
    showConvertButton: false,
    showConvertedButton: false,
  },
  getters: {},
  mutations: {
    test(state) {
      state.showConvertedButton = false;
      state.showConvertButton = true;
    },
  },
  actions: {},
};
