import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  expenses: [
    {
      id: 1,
      reference: "Reference",
      clientName: "Mahmoud",
      paidThrough: "Bank Account",
      vendorName: "Vendor Name",
      // amount: "Amount",
      // billed: "true",
      expenseAccount: "A",
      date: "Date",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return expenses
// ------------------------------------------------
mock.onGet("/books/expenses").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.expenses.filter(
    clientName => clientName.clientName.toLowerCase().includes(queryLowered),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      expenses: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new expense
// ------------------------------------------------
mock.onPost("/books/expenses").reply(config => {
  // Get event from post data
  const { expense } = JSON.parse(config.data);

  const { length } = data.expenses;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.expenses[length - 1].id;
  }
  expense.id = lastIndex + 1;

  data.expenses.push(expense);

  return [201, { expense }];
});

// ------------------------------------------------
// GET: Return Single expense
// ------------------------------------------------
mock.onGet(/\/books\/expenses\/\d+/).reply(config => {
  // Get event id from URL
  let expenseId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  expenseId = Number(expenseId);

  const expenseIndex = data.expenses.findIndex(e => e.id === expenseId);
  const expense = data.expenses[expenseIndex];

  if (expense) return [200, expense];
  return [404];
});
