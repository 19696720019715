import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  channels: [
    {
      id: 1,
      channels: "Facebook",
      spentBudget: "2000",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return channels
// ------------------------------------------------
mock.onGet("/marketing/channels").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.channels.filter(
    channel => channel.channels.toLowerCase().includes(queryLowered),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      channels: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new channel
// ------------------------------------------------
mock.onPost("/marketing/channels").reply(config => {
  // Get event from post data
  const { channel } = JSON.parse(config.data);

  const { length } = data.channels;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.channels[length - 1].id;
  }
  channel.id = lastIndex + 1;

  data.channels.push(channel);

  return [201, { channel }];
});

// ------------------------------------------------
// GET: Return Single channel
// ------------------------------------------------
mock.onGet(/\/marketing\/channels\/\d+/).reply(config => {
  // Get event id from URL
  let channelId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  channelId = Number(channelId);

  const channelIndex = data.channels.findIndex(e => e.id === channelId);
  const channel = data.channels[channelIndex];

  if (channel) return [200, channel];
  return [404];
});
