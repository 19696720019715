import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const date = new Date();
const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const createdAt = `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`;

const data = {
  permessions: [
    {
      id: 1,
      permessionName: "Mahmoud Galal",
      role: ["select role", "administrator", "support"],
      createdDate: createdAt,
    },
    {
      id: 2,
      permessionName: "Halsey Redmore",
      role: ["select role", "administrator", "manager"],
      createdDate: createdAt,
    },
    {
      id: 3,
      permessionName: "Marjory Sicely",
      role: ["select role", "administrator", "restricted user"],
      createdDate: createdAt,
    },
    {
      id: 4,
      permessionName: "Cyrill Risby",
      role: ["select role", "administrator", "manager"],
      createdDate: createdAt,
    },
    {
      id: 5,
      permessionName: "Maggy Hurran",
      role: ["select role", "administrator", "user", "support"],
      createdDate: createdAt,
    },
    {
      id: 6,
      permessionName: "Silvain Halstead",
      role: ["select role", "administrator", "user", "support"],
      createdDate: createdAt,
    },
    {
      id: 7,
      permessionName: "Breena Gallemore",
      role: ["select role", "administrator", "manager"],
      createdDate: createdAt,
    },
    {
      id: 8,
      permessionName: "Kathryne Liger",
      role: ["select role", "administrator"],
      createdDate: createdAt,
    },
    {
      id: 9,
      permessionName: "Franz Scotfurth",
      role: ["select role", "administrator"],
      createdDate: createdAt,
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return Permessions
// ------------------------------------------------
mock.onGet("/apps/permessions").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
    role = null,
    permessionName = "",
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.permessions.filter(
    permession =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (permession.permessionName.toLowerCase().includes(queryLowered) || permession.permessionName.toLowerCase().includes(queryLowered)) &&
      permession.role.includes(role || permession.role[0]) &&
      permession.permessionName === (permessionName || permession.permessionName),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      permessions: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new permession
// ------------------------------------------------
mock.onPost("/apps/permessions").reply(config => {
  // Get event from post data
  const { permession } = JSON.parse(config.data);

  // Assign Default role (my edit)
  permession.role = ["select role", "administrator"];

  const { length } = data.permessions;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.permessions[length - 1].id;
  }
  permession.id = lastIndex + 1;

  data.permessions.push(permession);

  return [201, { permession }];
});

// ------------------------------------------------
// GET: Return Single Permession
// ------------------------------------------------
mock.onGet(/\/apps\/permessions\/\d+/).reply(config => {
  // Get event id from URL
  let permessionId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  permessionId = Number(permessionId);

  const permessionIndex = data.permessions.findIndex(e => e.id === permessionId);
  const permession = data.permessions[permessionIndex];

  if (permession) return [200, permession];
  return [404];
});
