export default [
  {
    path: "/home-dashboard",
    name: "home",
    component: () => import("@/views/container/Home/HomeLeads.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Home",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
    // children: [
    //   {
    //     path: "home-leads",
    //     name: "home",
    //     component: () => import("@/views/container/Home/HomeLeads.vue"),
    //   },
    //   {
    //     path: "home-invoicing",
    //     component: () => import("@/views/container/Home/HomeInvoicing.vue"),
    //   },
    //   {
    //     path: "home-sales",
    //     component: () => import("@/views/container/Home/HomeSales.vue"),
    //   },
    //   {
    //     path: "home-expenses",
    //     component: () => import("@/views/container/Home/HomeExpenses.vue"),
    //   },
    //   {
    //     path: "home-payments",
    //     component: () => import("@/views/container/Home/HomePayments.vue"),
    //   },
    //   {
    //     path: "home-projects",
    //     component: () => import("@/views/container/Home/HomeProjects.vue"),
    //   },
    //   {
    //     path: "home-ticketing",
    //     component: () => import("@/views/container/Home/HomeTicketing.vue"),
    //   },
    // ],
  },
  {
    path: "/leads-table",
    name: "leads",
    component: () => import("@/views/container/Leads/LeadsTable.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
        {
          text: "Table",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "apps/calendar",
        components: {
          calendar: () => import("@/views/apps/calendar/Calendar.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/adresses",
        components: {
          adresses: () => import("@/views/apps/adresses/DetailedAdresses.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/orders",
        components: {
          adresses: () => import("@/views/apps/orders/Orders.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/files",
        components: {
          files: () => import("@/views/apps/files/Files.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/calls",
        components: {
          calls: () => import("@/views/apps/calls/Calls.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/activities",
        components: {
          activities: () => import("@/views/used-timeline/TimelineBasic.vue"),
        },
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/whatsapp",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/sms",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/email",
        name: "apps-email-leads",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
      },
      {
        path: "apps/email/:folder",
        name: "apps-email-leads-folder",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/email/label/:label",
        name: "apps-email-leads-label",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["personal", "company", "important", "private"].includes(to.params.label)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo",
        name: "apps-todo-leads",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
      },
      {
        path: "apps/todo/:filter",
        name: "apps-todo-leads-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["important", "completed", "deleted"].includes(to.params.filter)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo/tag/:tag",
        name: "apps-todo-leads-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
            },
            {
              text: "Table",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next();
          else next({ name: "error-404" });
        },
      },
    ],
  },
  {
    path: "/leads-Kanban",
    name: "kanban",
    component: () => import("@/views/container/Leads/LeadsKanban.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
        {
          text: "Kanban",
          active: true,
        },
      ],
    },
  },
  {
    path: "/deals",
    name: "deals",
    component: () => import("@/views/container/Deals.vue"),
    meta: {
      pageTitle: "Deals",
      breadcrumb: [
        {
          text: "Deals",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "apps/calendar",
        components: {
          calendar: () => import("@/views/apps/calendar/Calendar.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/adresses",
        components: {
          adresses: () => import("@/views/apps/adresses/DetailedAdresses.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/orders",
        components: {
          adresses: () => import("@/views/apps/orders/Orders.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/files",
        components: {
          files: () => import("@/views/apps/files/Files.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/activities",
        components: {
          activities: () => import("@/views/used-timeline/TimelineBasic.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/calls",
        components: {
          calls: () => import("@/views/apps/calls/Calls.vue"),
        },
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/whatsapp",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/sms",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/email",
        name: "apps-email-deals",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
      },
      {
        path: "apps/email/:folder",
        name: "apps-email-deals-folder",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/email/label/:label",
        name: "apps-email-deals-label",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["personal", "company", "important", "private"].includes(to.params.label)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo",
        name: "apps-todo-deals",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
      },
      {
        path: "apps/todo/:filter",
        name: "apps-todo-deals-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["important", "completed", "deleted"].includes(to.params.filter)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo/tag/:tag",
        name: "apps-todo-deals-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Deals",
          breadcrumb: [
            {
              text: "Deals",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next();
          else next({ name: "error-404" });
        },
      },
    ],
  },
  {
    path: "/shipments",
    name: "shipments",
    component: () => import("@/views/container/Shipments.vue"),
    meta: {
      pageTitle: "Shipments",
      breadcrumb: [
        {
          text: "Shipments",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "apps/calendar",
        components: {
          calendar: () => import("@/views/apps/calendar/Calendar.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
        },
      },
      {
        path: "apps/adresses",
        components: {
          adresses: () => import("@/views/apps/adresses/DetailedAdresses.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
        },
      },
      {
        path: "apps/orders",
        components: {
          adresses: () => import("@/views/apps/orders/Orders.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/files",
        components: {
          files: () => import("@/views/apps/files/Files.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
        },
      },
      {
        path: "apps/calls",
        components: {
          calls: () => import("@/views/apps/calls/Calls.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
        },
      },
      {
        path: "apps/activities",
        components: {
          activities: () => import("@/views/used-timeline/TimelineBasic.vue"),
        },
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
        },
      },
      {
        path: "apps/whatsapp",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/sms",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/email",
        name: "apps-email-shipments",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "email-application",
        },
      },
      {
        path: "apps/email/:folder",
        name: "apps-email-shipments-folder",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/email/label/:label",
        name: "apps-email-shipments-label",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["personal", "company", "important", "private"].includes(to.params.label)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo",
        name: "apps-todo-shipments",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "todo-application",
        },
      },
      {
        path: "apps/todo/:filter",
        name: "apps-todo-shipments-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["important", "completed", "deleted"].includes(to.params.filter)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo/tag/:tag",
        name: "apps-todo-shipments-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Shipments",
          breadcrumb: [
            {
              text: "Shipments",
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next();
          else next({ name: "error-404" });
        },
      },
    ],
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/container/Contacts.vue"),
    meta: {
      pageTitle: "Contacts",
      breadcrumb: [
        {
          text: "Contacts",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "apps/calendar",
        components: {
          calendar: () => import("@/views/apps/calendar/Calendar.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/adresses",
        components: {
          adresses: () => import("@/views/apps/adresses/DetailedAdresses.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/orders",
        components: {
          adresses: () => import("@/views/apps/orders/Orders.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/files",
        components: {
          files: () => import("@/views/apps/files/Files.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/calls",
        components: {
          calls: () => import("@/views/apps/calls/Calls.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/activities",
        components: {
          activities: () => import("@/views/used-timeline/TimelineBasic.vue"),
        },
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/whatsapp",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/sms",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/email",
        name: "apps-email-contacts",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
      },
      {
        path: "apps/email/:folder",
        name: "apps-email-contacts-folder",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/email/label/:label",
        name: "apps-email-contacts-label",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["personal", "company", "important", "private"].includes(to.params.label)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo",
        name: "apps-todo-contacts",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
      },
      {
        path: "apps/todo/:filter",
        name: "apps-todo-contacts-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["important", "completed", "deleted"].includes(to.params.filter)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo/tag/:tag",
        name: "apps-todo-contacts-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Contacts",
          breadcrumb: [
            {
              text: "Contacts",
              active: true,
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next();
          else next({ name: "error-404" });
        },
      },
    ],
  },
  {
    path: "/marketing/campaigns",
    name: "campaigns",
    component: () => import("@/views/container/Marketing/campaigns/Campaigns.vue"),
    meta: {
      pageTitle: "Campaigns",
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Campaigns",
          active: true,
        },
      ],
    },
  },
  {
    path: "/marketing/campaigns/campaign/:id",
    name: "marketing-campaigns-campaign",
    component: () => import("@/views/container/Marketing/campaigns/campaigns-view/CampaignsView.vue"),
  },
  {
    path: "/marketing/channels",
    name: "channels",
    component: () => import("@/views/container/Marketing/channels/Channels.vue"),
    meta: {
      pageTitle: "Channels",
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Channels",
          active: true,
        },
      ],
    },
  },
  {
    path: "/marketing/medium",
    name: "medium",
    component: () => import("@/views/container/Marketing/medium/Medium.vue"),
    meta: {
      pageTitle: "Medium",
      breadcrumb: [
        {
          text: "Marketing",
        },
        {
          text: "Medium",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/templates",
    name: "landing-page-templates",
    component: () => import("@/views/container/landing-page/LandingPageTemplates.vue"),
    meta: {
      pageTitle: "Templates",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "Templates",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/urls",
    name: "landing-page-urls",
    component: () => import("@/views/container/landing-page/LandingPageUrls.vue"),
    meta: {
      pageTitle: "URLs",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "URLs",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/domains",
    name: "landing-page-domains",
    component: () => import("@/views/container/landing-page/LandingPageDomains.vue"),
    meta: {
      pageTitle: "Domains",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "Domains",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/conversion-tracking",
    name: "landing-page-conversion-tracking",
    component: () => import("@/views/container/landing-page/LandingPageConversionTracking.vue"),
    meta: {
      pageTitle: "Conversion tracking",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "Conversion tracking",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/forms",
    name: "landing-page-forms",
    component: () => import("@/views/container/landing-page/LandingPageForms.vue"),
    meta: {
      pageTitle: "Forms",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "Forms",
          active: true,
        },
      ],
    },
  },
  {
    path: "/landing-page/split-testing",
    name: "landing-page-split-testing",
    component: () => import("@/views/container/landing-page/LandingPageSplitTesting.vue"),
    meta: {
      pageTitle: "Split testing",
      breadcrumb: [
        {
          text: "Landing page",
        },
        {
          text: "Split testing",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/order/list",
    name: "books-orders",
    component: () => import("@/views/container/books/orders/order-list/OrderList.vue"),
    meta: {
      pageTitle: "Orders",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/order/preview/:id",
    name: "books-order-preview",
    component: () => import("@/views/container/books/orders/order-preview/OrderPreview.vue"),
    meta: {
      pageTitle: "Orders",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/order/add/",
    name: "books-order-add",
    component: () => import("@/views/container/books/orders/order-add/OrderAdd.vue"),
    meta: {
      pageTitle: "Orders",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/estimates",
    name: "books-estimates",
    component: () => import("@/views/container/books/estimates/estimate-list/EstimateList.vue"),
    meta: {
      pageTitle: "Estimates",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Estimates",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/estimate/preview/:id",
    name: "books-estimate-preview",
    component: () => import("@/views/container/books/estimates/estimate-preview/EstimatePreview.vue"),
    meta: {
      pageTitle: "Estimates",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Estimates",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/estimate/add/",
    name: "books-estimate-add",
    component: () => import("@/views/container/books/estimates/estimate-add/EstimateAdd.vue"),
    meta: {
      pageTitle: "Estimates",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Estimates",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/estimate/edit/:id",
    name: "books-estimate-edit",
    component: () => import("@/views/container/books/estimates/estimate-edit/EstimateEdit.vue"),
    meta: {
      pageTitle: "Estimates",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Estimates",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/credit-notes",
    name: "books-credit-notes",
    component: () => import("@/views/container/books/credit-notes/credit-note-list/CreditNoteList.vue"),
    meta: {
      pageTitle: "Credit Notes",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Credit Notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/credit-note/preview/:id",
    name: "books-credit-note-preview",
    component: () => import("@/views/container/books/credit-notes/credit-note-preview/CreditNotePreview.vue"),
    meta: {
      pageTitle: "Credit Notes",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Credit Notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/credit-note/add/",
    name: "books-credit-note-add",
    component: () => import("@/views/container/books/credit-notes/credit-note-add/CreditNoteAdd.vue"),
    meta: {
      pageTitle: "Credit Notes",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Credit Notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/credit-note/edit/:id",
    name: "books-credit-note-edit",
    component: () => import("@/views/container/books/credit-notes/credit-note-edit/CreditNoteEdit.vue"),
    meta: {
      pageTitle: "Credit Notes",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Credit Notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/payments-received",
    name: "books-payments-received",
    component: () => import("@/views/container/books/payments/payments-list/BooksPaymentsList.vue"),
    meta: {
      pageTitle: "Payments received",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Payments received",
          active: true,
        },
      ],
    },
  },
  {
    path: "/books/expenses",
    name: "books-expenses",
    component: () => import("@/views/container/books/expenses/BooksExpenses.vue"),
    meta: {
      pageTitle: "Expenses",
      breadcrumb: [
        {
          text: "Books",
        },
        {
          text: "Expenses",
          active: true,
        },
      ],
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("@/views/container/Projects.vue"),
    meta: {
      pageTitle: "Projects",
      breadcrumb: [
        {
          text: "Projects",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "apps/calendar",
        components: {
          calendar: () => import("@/views/apps/calendar/Calendar.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
        },
      },
      {
        path: "apps/adresses",
        components: {
          adresses: () => import("@/views/apps/adresses/DetailedAdresses.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
        },
      },
      {
        path: "apps/orders",
        components: {
          adresses: () => import("@/views/apps/orders/Orders.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
              active: true,
            },
          ],
        },
      },
      {
        path: "apps/files",
        components: {
          files: () => import("@/views/apps/files/Files.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
        },
      },
      {
        path: "apps/calls",
        components: {
          calls: () => import("@/views/apps/calls/Calls.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
        },
      },
      {
        path: "apps/activities",
        components: {
          activities: () => import("@/views/used-timeline/TimelineBasic.vue"),
        },
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
        },
      },
      {
        path: "apps/whatsapp",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/sms",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "chat-application",
        },
      },
      {
        path: "apps/email",
        name: "apps-email-projects",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "email-application",
        },
      },
      {
        path: "apps/email/:folder",
        name: "apps-email-projects-folder",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["sent", "draft", "starred", "spam", "trash"].includes(to.params.folder)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/email/label/:label",
        name: "apps-email-projects-label",
        component: () => import("@/views/apps/email/Email.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "email-application",
        },
        beforeEnter(to, _, next) {
          if (["personal", "company", "important", "private"].includes(to.params.label)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo",
        name: "apps-todo-projects",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "todo-application",
        },
      },
      {
        path: "apps/todo/:filter",
        name: "apps-todo-projects-filter",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["important", "completed", "deleted"].includes(to.params.filter)) next();
          else next({ name: "error-404" });
        },
      },
      {
        path: "apps/todo/tag/:tag",
        name: "apps-todo-projects-tag",
        component: () => import("@/views/apps/todo/Todo.vue"),
        meta: {
          pageTitle: "Projects",
          breadcrumb: [
            {
              text: "Projects",
            },
          ],
          contentClass: "todo-application",
        },
        beforeEnter(to, _, next) {
          if (["team", "low", "medium", "high", "update"].includes(to.params.tag)) next();
          else next({ name: "error-404" });
        },
      },
    ],
  },
  // {
  //   path: "/messages",
  //   name: "messages",
  //   component: () => import("@/views/container/messages/Messages.vue"),
  //   meta: {
  //     pageTitle: "Messages",
  //     breadcrumb: [
  //       {
  //         text: "Messages",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/users/users-list",
    name: "users-list",
    component: () => import("@/views/container/users/Users.vue"),
    meta: {
      pageTitle: "Users list",
      breadcrumb: [
        {
          text: "Users",
        },
        {
          text: "Users list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-roles",
    name: "users-roles",
    component: () => import("@/views/container/users/roles/UsersRoles.vue"),
    meta: {
      pageTitle: "Roles List",
      breadcrumb: [
        {
          text: "Users",
        },
        {
          text: "Roles List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/users/users-permessions",
    name: "users-permessions",
    component: () => import("@/views/container/users/permessions/UsersPermessions.vue"),
    meta: {
      pageTitle: "Permissions List",
      breadcrumb: [
        {
          text: "Users",
        },
        {
          text: "Permissions List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/notes",
    name: "notes",
    component: () => import("@/views/container/Notes.vue"),
    meta: {
      pageTitle: "Notes",
      breadcrumb: [
        {
          text: "Notes",
          active: true,
        },
      ],
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("@/views/container/Reports.vue"),
    meta: {
      pageTitle: "Reports",
      breadcrumb: [
        {
          text: "Reports",
          active: true,
        },
      ],
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/container/settings/Settings.vue"),
    meta: {
      pageTitle: "Settings",
      breadcrumb: [
        {
          text: "Settings",
          active: true,
        },
      ],
    },
  },
];
