import mock from "@/@fake-db/mock";
import { paginateArray, sortCompare } from "@/@fake-db/utils";

/* eslint-disable global-require */
const data = {
  payments: [
    {
      id: 1,
      reference: "Reference",
      clientName: "Mahmoud",
      mode: "Cash",
      invoice: "Invoice 1",
      amount: "Amount",
      unusedAmount: "Unused Amount",
      payment: "1000",
      date: "Date",
    },
  ],
};
/* eslint-enable */

// ------------------------------------------------
// GET: Return payments
// ------------------------------------------------
mock.onGet("/books/payments").reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = "",
    perPage = 10,
    page = 1,
    sortBy = "id",
    sortDesc = false,
  } = config.params;
  /* eslint-enable */

  const queryLowered = q.toLowerCase();
  const filteredData = data.payments.filter(
    clientName => clientName.clientName.toLowerCase().includes(queryLowered),
  );
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy));
  if (sortDesc) sortedData.reverse();

  return [
    200,
    {
      payments: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ];
});

// ------------------------------------------------
// POST: Add new payment
// ------------------------------------------------
mock.onPost("/books/payments").reply(config => {
  // Get event from post data
  const { payment } = JSON.parse(config.data);

  const { length } = data.payments;
  let lastIndex = 0;
  if (length) {
    lastIndex = data.payments[length - 1].id;
  }
  payment.id = lastIndex + 1;

  data.payments.push(payment);

  return [201, { payment }];
});

// ------------------------------------------------
// GET: Return Single payment
// ------------------------------------------------
mock.onGet(/\/books\/payments\/\d+/).reply(config => {
  // Get event id from URL
  let paymentId = config.url.substring(config.url.lastIndexOf("/") + 1);

  // Convert Id to number
  paymentId = Number(paymentId);

  const paymentIndex = data.payments.findIndex(e => e.id === paymentId);
  const payment = data.payments[paymentIndex];

  if (payment) return [200, payment];
  return [404];
});
